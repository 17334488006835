@use '_base.scss';
@use 'Colors.module.scss';

.link,
.link:visited {
  color: Colors.$blue;
  text-decoration: none;
  font-weight: bold;
}

//* <div> divider, separator, separater, etc etc
.separator {
  height: 1px;
  margin: 24px 0px;
  background-color: Colors.$gray-light;
}

.flex {
  display: flex;
}

.inlineFlex {
  display: inline-flex;
}

.flexRow {
  flex-direction: row;
}

.itemsCenter {
  align-items: center;
}
.gap4 {
  gap: 4px,
}
.cursorPointer {
  cursor: pointer;
}
.displayBigDesktopUpOnly {
  display: none;

  @media screen and (min-width: 1800px) {
    display: inline;
  }
}
.borderBottomNone {
  border-bottom: 0 !important;
}
.hideBelow1280 {
  @media screen and (max-width: 1280px) {
    display: none !important;
  }
}